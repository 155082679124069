import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import CheckIcon from '@material-ui/icons/Check';
import { Field, Form, Formik } from 'formik';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Bugsnag from 'lib/bugsnag';
import { contactListSubscribe } from 'lib/mailjet';
import { Mouseflow } from 'lib/mouseflow';
import Yup from 'lib/yup';
import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';
import FormikFormField from 'shared/components/FormikFormField';

const FORM_ID = 'sign-up-form';

interface Props {
  imageSrc?: string;
  title?: string;
  subTitle?: string;
}

interface FormValues {
  email: string;
}

const DEFAULT_FORM_VALUES: FormValues = {
  email: '',
};

function SignUpForm({ imageSrc, title, subTitle }: Props) {
  const { t } = useTranslation('hub');
  const mobile = useBreakpointDown('sm');
  const [submissionResult, setSumbissionResult] = useState<
    null | 'success' | 'failure' | false
  >(null);

  const closeFeedbackMessageTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (!submissionResult) {
      return;
    }
    closeFeedbackMessageTimeoutRef.current = setTimeout(() => {
      setSumbissionResult(false);
    }, 4000);

    return () => {
      if (closeFeedbackMessageTimeoutRef.current) {
        clearTimeout(closeFeedbackMessageTimeoutRef.current);
      }
    };
  }, [submissionResult]);

  const hasImage = Boolean(imageSrc && !mobile);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection={{ xs: 'column', sm: 'row' }}
    >
      {hasImage && imageSrc && (
        <ImageWrapper>
          <Image
            alt=""
            src={imageSrc}
            width={448}
            height={411}
            layout="responsive"
            objectFit="cover"
          />
        </ImageWrapper>
      )}
      <ContentWrapper $isImage={hasImage}>
        <Box mb={{ xs: 3, md: 2 }} textAlign={{ xs: 'center', sm: 'left' }}>
          <Typography
            variant="h3"
            lineHeight="146%"
            pr={{ xs: 0, lg: 10 }}
            mb={{ xs: 3, md: 5 }}
            fontSize={{ xs: '24px', md: '36px' }}
          >
            {title || t('common:signUpForm.title')}
          </Typography>

          <Typography variant="body2" gutterBottom>
            {subTitle || t('common:signUpForm.subtitle')}
          </Typography>
        </Box>

        <Formik
          initialValues={DEFAULT_FORM_VALUES}
          validationSchema={Yup.object({
            email: Yup.string()
              .required(t('common:signUpForm.emailRequired'))
              .email(t('common:signUpForm.correctFormatEmail')),
          })}
          onSubmit={async (values, actions) => {
            try {
              setSumbissionResult(null);
              Mouseflow.notify.formSubmitAttempt(FORM_ID);
              await contactListSubscribe(values.email);
              Mouseflow.notify.formSubmitSuccess(FORM_ID);
              setSumbissionResult('success');
            } catch (e) {
              if (e instanceof Error) {
                Mouseflow.notify.formSubmitFailure(FORM_ID);
                Bugsnag.notify(e);
              }
              setSumbissionResult('failure');
            } finally {
              actions.resetForm({ values: { email: '' } });
            }
          }}
          enableReinitialize
        >
          {(formikBag) => {
            return (
              <StyledForm id={FORM_ID}>
                <Typography
                  variant={mobile ? 'label' : 'srOnly'}
                  component="label"
                  fontSize={{ xs: 12 }}
                  fontWeight={500}
                  htmlFor="email"
                  ml={1}
                >
                  {'Email *'}
                </Typography>

                <StyledContainer>
                  <Box maxWidth="600px">
                    <Field
                      placeholder={'Email'}
                      name="email"
                      id="email"
                      type="email"
                      component={FormikFormField}
                    />
                  </Box>
                  <StyledButton
                    disabled={!formikBag.dirty}
                    size="lg"
                    variant="secondary"
                    type="submit"
                    role="button"
                    aria-label="submit"
                    id="contact-caterer-modal-submit"
                  >
                    {t('common:signUpForm.button')}
                  </StyledButton>
                </StyledContainer>
                {submissionResult && (
                  <Box alignSelf="center">
                    {submissionResult === 'success' ? (
                      <StyledBox $type="success">
                        <CheckIcon color="inherit" />
                        <Typography variant="caption" color="inherit" ml={1}>
                          {t('common:signUpForm.successMessage')}
                        </Typography>
                      </StyledBox>
                    ) : (
                      <StyledBox $type="failure">
                        <Typography variant="caption" color="inherit" ml={1}>
                          {t('common:signUpForm.errorMessage')}
                        </Typography>
                      </StyledBox>
                    )}
                  </Box>
                )}
              </StyledForm>
            );
          }}
        </Formik>
        <FooterSocialLinks>
          <FooterSocialIconLink
            href={'https://www.facebook.com/heycater'}
            target="_blank"
            rel="noopener"
          >
            <Image
              alt="facebook logo"
              src={'/artifacts/images/hub/socials/facebook.svg'}
              width={40}
              height={40}
            />
          </FooterSocialIconLink>
          <FooterSocialIconLink
            href={'https://www.linkedin.com/company/heycater/'}
            target="_blank"
            rel="noopener"
          >
            <Image
              alt="linkedin logo"
              src={'/artifacts/images/hub/socials/linkedin.svg'}
              width={40}
              height={40}
            />
          </FooterSocialIconLink>
          <FooterSocialIconLink
            href={'https://www.instagram.com/heycater/'}
            target="_blank"
            rel="noopener"
          >
            <Image
              alt="instagram logo"
              src={'/artifacts/images/hub/socials/instagram.svg'}
              width={40}
              height={40}
            />
          </FooterSocialIconLink>
          <Typography variant="caption">
            {t('cta.ctaSignupSocialsText')}
          </Typography>
        </FooterSocialLinks>
      </ContentWrapper>
    </Box>
  );
}

const FooterSocialLinks = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)}px;
  align-items: center;
`;

const StyledForm = styled(Form)`
  .hc-form-control {
    margin: 0;
  }
`;

const ImageWrapper = styled.div`
  width: 40%;
  margin-right: ${({ theme }) => theme.spacing(7)}px;
  img {
    border-radius: ${(props) => props.theme.spacing(1)}px;
  }
`;

const ContentWrapper = styled.div<{ $isImage: boolean }>`
  width: ${({ $isImage }) => ($isImage ? '60%' : 'auto')};
  p {
    margin: 0;
  }
`;

const StyledContainer = styled.div`
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  gap: ${(props) => props.theme.spacing(1)}px;

  div {
    width: 100%;
  }

  margin-bottom: ${({ theme }) => theme.spacing(3)}px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    gap: ${(props) => props.theme.spacing(2)}px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: auto;
  }
`;

const FooterSocialIconLink = styled.a`
  color: inherit;
  cursor: pointer;
  display: contents;
`;

const StyledBox = styled.div<{ $type: 'success' | 'failure' }>`
  display: flex;
  align-items: center;
  background-color: ${({ theme, $type }) =>
    $type === 'success'
      ? theme.palette.secondary.light
      : theme.palette.error.light};

  padding: ${(props) => props.theme.spacing(1, 1, 1, 1)};
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
  width: 100%;
  border-radius: ${(props) => props.theme.spacing(1)}px;
  color: ${(props) => props.theme.palette.common.white};

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-left: 0;
  }
`;

export default SignUpForm;
