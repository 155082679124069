import React from 'react';
import Image from 'next/image';

import { getContentfulImageUrl } from 'hub/helpers/contentful';

function ContentfulImage({
  src,
  width,
  height,
  layout = 'responsive',
  alt = '',
  ...restProps
}) {
  return (
    <Image
      alt={alt}
      src={getContentfulImageUrl(src)}
      width={width}
      height={height}
      layout={layout}
      objectFit={'cover'}
      quality="85"
      {...restProps}
    />
  );
}

export default ContentfulImage;
