import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import { Divider, Grid, useMediaQuery } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import CategoriesBanner from 'hub/components/CategoriesBanner';
import FeaturedArticleCard from 'hub/components/FeaturedArticleCard';
import FeaturedPosts from 'hub/components/FeaturedPosts';
import HubArticleCard from 'hub/components/HubArticleCard';
import SignUpForm from 'hub/components/SignUpForm';
import { localeMapping } from 'lib/contentful/client';
import {
  fetchArticleCardsByCategory,
  fetchArticleCategories,
  fetchFeaturedArticle,
  fetchHubHomepage,
} from 'lib/contentful/hub';
import MainLayout from 'shared/layouts/MainLayout';
import { fetchRatings } from 'shared/services/trustedShops/fetchRatings';
import FooterClean from 'static_pages/marketing_pages/components/FooterClean';
import Container from 'system/components/Container';

function Hub({
  heroTitle,
  heroSubtitle,
  featuredArticle,
  featuredPosts,
  categories,
  footerTitle,
  footerCta,
  ratings,
}) {
  const { t } = useTranslation('hub');
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(0);
  const articlesPageSize = 6;
  const [showAllButton, setShowAllButton] = useState(false);

  const categoryMappings = {};

  categories.map(
    (category) => (categoryMappings[category.slug] = category.title)
  );

  useEffect(() => {
    const fetchCategoryArticles = async () => {
      const data = await fetchArticleCardsByCategory({
        category: selectedCategory,
        page: page,
        pageLimit: articlesPageSize,
      });
      setArticles([...articles, ...data.items]);
      setShowAllButton(page * articlesPageSize < data.total);
    };

    fetchCategoryArticles().catch(console.error);
  }, [page]);

  useEffect(() => {
    const fetchCategoryArticles = async () => {
      const data = await fetchArticleCardsByCategory({
        category: selectedCategory,
        page: 0,
        pageLimit: articlesPageSize,
      });
      setArticles(data.items);
      setPage(0);
      setShowAllButton(page * articlesPageSize < data.total);
    };

    fetchCategoryArticles().catch(console.error);
  }, [selectedCategory]);

  const onToggleDropdownVisibility = React.useCallback(
    (name) => {
      if (!mobile) return;

      if (name === visibleDropdown) {
        setVisibleDropdown(null);
      } else {
        setVisibleDropdown(name);
      }
    },
    [visibleDropdown, mobile]
  );

  return (
    <MainLayout ratings={ratings}>
      <Head>
        <title>Blog | heycater!</title>
      </Head>
      <Box bgcolor="#f8f8f8">
        <Container size="sm">
          <header>
            <TypographyPageTitle
              pt={{ xs: 3, md: 9 }}
              variant="h2"
              component="h1"
              textAlign="center"
              mb={1}
            >
              {heroTitle}
            </TypographyPageTitle>
            <Typography
              variant="subtitle2"
              textAlign="center"
              component="p"
              pt={3}
              pb={{ xs: 5, md: 8 }}
            >
              {heroSubtitle}
            </Typography>
          </header>
        </Container>
      </Box>
      <CategoriesBanner
        categories={categories}
        activeCategory={'all'}
        title={
          <Box pt={2} pb={1} aria-hidden>
            <TypographyCategoriesLabel
              variant="caption"
              display="block"
              align="center"
            >
              {t('category.blogCategories')}
            </TypographyCategoriesLabel>
          </Box>
        }
      />
      <Container size="md">
        <Box pt={{ xs: 4, md: 6 }}>
          <FeaturedArticleCard
            title={featuredArticle?.title}
            description={featuredArticle?.description}
            hubslug={featuredArticle?.hubslug}
            heroImage={featuredArticle?.heroImage}
            category={featuredArticle?.category}
          />
        </Box>
        <FeaturedPosts items={featuredPosts} title={'Featured Posts'} />
        <Divider />
        <FeaturedPosts
          category={categories.find(({ slug }) => slug == 'hey-wissen')}
          title={t('category.heyWissen')}
          href={`/hub/hey-wissen`}
        />
        <Divider />
        <FeaturedPosts
          category={categories.find(({ slug }) => slug == 'hey-wiki')}
          title={t('category.heyWiki')}
          href={`/hub/hey-wiki`}
        />
        <Divider />
        <Box mb={{ xs: 3, sm: 7 }} mt={{ xs: 4, sm: 7 }}>
          <SignUpForm
            imageSrc="/artifacts/images/hub/heycater_hub.webp"
            title={t('signUpForm.hubHomePage.title')}
            subTitle={t('signUpForm.hubHomePage.subTitle')}
          />
        </Box>
        <Divider />
        <FeaturedPosts
          category={categories.find(({ slug }) => slug == 'hey-community')}
          title={t('category.heyCommunity')}
          href={`/hub/hey-community`}
        />
        <Divider />
        <FeaturedPosts
          category={categories.find(({ slug }) => slug == 'hey-partner')}
          title={t('category.heyPartner')}
          href={`/hub/hey-partner`}
        />
        <Divider />
        <FeaturedPosts
          category={categories.find(({ slug }) => slug == 'hey-inside')}
          title={t('category.heyInside')}
          href={`/hub/hey-inside`}
        />
        <Divider />
        <Box mt={{ xs: 4, md: 7 }} mb={{ xs: 4, md: 12 }}>
          <Box mb={{ xs: 3, md: 7 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
              width="100%"
            >
              <Typography variant={mobile ? 'subtitle2' : 'h4'}>
                {t('browseAllText')}
              </Typography>
              {!mobile && (
                <MenuListItem
                  expanded={visibleDropdown === 'categories'}
                  data-dropdown="true"
                >
                  <MenuListItemLink
                    as="div"
                    onClick={() => onToggleDropdownVisibility('categories')}
                  >
                    {categoryMappings[selectedCategory] || 'Select a category'}
                    {visibleDropdown === 'categories' ? (
                      <KeyboardArrowUpIcon fontSize="small" />
                    ) : (
                      <KeyboardArrowDownIcon fontSize="small" />
                    )}
                  </MenuListItemLink>
                  <Dropdown
                    visible={visibleDropdown === 'categories'}
                    className="navigation-dropdown"
                  >
                    <Box p={0.5}>
                      {categories.map((category) => (
                        <DropdownMenuLink
                          key={category.slug}
                          onClick={() => setSelectedCategory(category.slug)}
                        >
                          {category.title}
                        </DropdownMenuLink>
                      ))}
                    </Box>
                  </Dropdown>
                </MenuListItem>
              )}
            </Box>
          </Box>
          <Grid container spacing={6}>
            {articles.map((article) => (
              <Grid key={article.slug} xs={12} md={4} item>
                <HubArticleCard
                  {...article}
                  key={article.slug}
                  mb={{ xs: 0, md: 3 }}
                />
              </Grid>
            ))}
          </Grid>
          <Box textAlign="center" pt={9}>
            {showAllButton == true && (
              <LoadMoreButton
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                <Typography fontWeight="600" fontSize="14px">
                  {t('articles.seeMore')}
                </Typography>
              </LoadMoreButton>
            )}
          </Box>
        </Box>
      </Container>
      <FooterClean title={footerTitle} cta={footerCta} link={'start'} />
    </MainLayout>
  );
}

export async function getStaticProps({ locale }) {
  const formattedLocale = localeMapping[locale];
  const [
    featuredArticle,
    categories,
    hubHomepage,
    ratings,
  ] = await Promise.all([
    fetchFeaturedArticle(formattedLocale),
    fetchArticleCategories(formattedLocale),
    fetchHubHomepage(formattedLocale),
    fetchRatings(),
  ]);

  const posts = hubHomepage.featuredPosts.map((post) => {
    return {
      fields: {
        title: post.fields.title,
        description: post.fields.description,
        hubslug: post.fields.hubslug,
        heroImage: post.fields.heroImage,
        tags: post.metadata.tags,
        category: getCategoryFromSlug(post?.fields?.hubslug, categories),
      },
    };
  });
  return {
    props: {
      heroTitle: hubHomepage.heroTitle,
      ratings,
      heroSubtitle: hubHomepage.heroSubtitle,
      featuredArticle: {
        ...featuredArticle,
        category: getCategoryFromSlug(featuredArticle?.hubslug, categories),
      },
      featuredPosts: posts,
      categories,
      footerTitle: hubHomepage.footerTitle,
      footerCta: hubHomepage.footerCta,
    },
  };
}

function getCategoryFromSlug(slug, categories) {
  const postCategorySlug = slug.split('/')?.[0];
  const category = categories.find(
    (category) => category.slug === postCategorySlug
  );
  return category || null;
}

const TypographyCategoriesLabel = styled(Typography)`
  color: ${(props) => props.theme.palette.text.faded};
  opacity: 0.5;
`;

const TypographyPageTitle = styled(Typography)`
  font-size: 48px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 36px;
  }
`;

const LoadMoreButton = styled(Button)`
  background-color: #9cba6c;
  border: none;
  :hover {
    background-color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const MenuListItemLink = styled.a`
  padding: ${(props) => props.theme.spacing(2, 2)};
  display: flex;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  align-items: center;
  background-color: ${(props) => props.theme.palette.text.disabled};
  border-radius: ${(props) => props.theme.spacing(1)}px;
  color: ${(props) => props.theme.palette.text.secondary};
  width: 293px;

  svg {
    position: absolute;
    right: 10px;
  }

  &,
  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-weight: ${(props) => props.theme.typography.h5.fontWeightBold};
    justify-content: space-between;
  }
`;

const MenuListItem = styled.li`
  position: relative;
  min-height: ${(props) => props.theme.navbar.height};
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.text.secondary};

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: block;
    min-height: auto;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    &[data-dropdown='true']:hover > .navigation-dropdown {
      display: block;
    }
  }
`;

const Dropdown = styled.div`
  background: white;
  background-color: ${(props) => props.theme.palette.text.disabled};
  min-height: 30px;
  overflow: hidden;
  position: absolute;
  top: ${(props) => props.theme.navbar.height};
  z-index: ${(props) => props.theme.zIndex.appBar};
  padding: ${(props) => props.theme.spacing(1)}px;
  left: 0px;
  min-width: 293px;
  border-left: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-right: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[200]};
  display: none;

  ${(props) => props.theme.breakpoints.down('sm')} {
    position: static;
    background: transparent;
    padding: ${(props) => props.theme.spacing(0, 4)}px;
    left: 0;
    width: 100%;
    border: none;
    border-top: 1px solid ${(props) => props.theme.palette.grey[200]};
    border-bottom: 1px solid ${(props) => props.theme.palette.grey[200]};
    background: ${(props) => props.theme.palette.grey[50]};
    display: ${(props) => (props.visible ? 'block' : 'none')};
  }
`;

const DropdownMenuLink = styled.a`
  display: block;
  padding: ${(props) => props.theme.spacing(1, 0)};
  color: ${(props) => props.theme.palette.text.primary};
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.text.secondary};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 14px;
  }
`;

export default Hub;
