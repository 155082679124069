import React, { useEffect, useRef } from 'react';
import { Box } from '@heycater/design-system';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Sticky } from 'hub/components/Sticky';
import { cssVars } from 'lib/cssVariables';
import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';

function CategoriesBanner({ categories, activeCategory, title }) {
  const isMobile = useBreakpointDown('sm');
  const { t } = useTranslation('hub');
  const router = useRouter();

  const activeCategoryRef = useRef(null);

  useEffect(() => {
    if (!activeCategoryRef?.current || !isMobile) {
      return;
    }

    const scrollActiveCategoryToCenter = () => {
      activeCategoryRef.current.scrollIntoView({
        inline: 'center',
        block: 'end',
        behavior: 'smooth',
      });
    };

    scrollActiveCategoryToCenter();
  }, [router.asPath, isMobile]);

  return (
    <>
      {title && <Box bgcolor={'#f8f8f8'}>{title}</Box>}
      <Sticky
        hideOnScroll={isMobile}
        as="nav"
        top={`var(${cssVars.topBarHeight.name})`}
        aria-label={t('category.blogCategories')}
      >
        <CategoryList>
          <ListItem>
            <Link href={'/hub'} passHref>
              <CategoryLink
                ref={activeCategory === 'all' ? activeCategoryRef : undefined}
                active={activeCategory === 'all'}
              >
                Hub Homepage
              </CategoryLink>
            </Link>
          </ListItem>
          {categories.map((category) => (
            <ListItem key={category.slug}>
              <Link href={`/hub/${category.slug}`} passHref>
                <CategoryLink
                  as="a"
                  key={category.slug}
                  ref={
                    activeCategory.slug === category.slug
                      ? activeCategoryRef
                      : undefined
                  }
                  active={activeCategory.slug === category.slug}
                >
                  {category.title}
                </CategoryLink>
              </Link>
            </ListItem>
          ))}
        </CategoryList>
      </Sticky>
    </>
  );
}

const ListItem = styled.li`
  padding: 0;
  flex-shrink: 0;
  margin: 0;
`;

const CategoryList = styled.ul`
  padding: ${({ theme }) => theme.spacing(2)}px;
  list-style: none;
  display: flex;
  margin: 0;
  overflow-x: auto;
  background-color: #f8f8f8;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: flex-start;
  }
`;

const CategoryLink = styled.a`
  display: block;
  margin-right: 1rem;
  color: ${({ active, theme }) =>
    active ? theme.palette.common.white : theme.palette.text.secondary};
  text-decoration: none;
  background: ${({ active, theme }) =>
    active ? theme.palette.secondary.light : theme.palette.text.disabled};
  border-radius: 500px;
  padding: ${({ theme }) => theme.spacing(1, 2.5)};
  font-size: 14px;
  font-weight: 600;

  &:hover {
    background: ${({ active }) => !active && 'hsla(69, 53%, 70%, .19)'};
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
`;

export default CategoriesBanner;
