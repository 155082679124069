import React, { useEffect, useState } from 'react';
import { Box } from '@heycater/design-system';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';

export const Sticky = ({ children, top, hideOnScroll, ...restProps }) => {
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [isHiddenOnScroll, setHiddenOnScroll] = useState(false);
  const { ref, entry } = useInView({ threshold: 1 });

  const height = entry?.boundingClientRect?.height;

  const handleScroll = () => {
    if (!hideOnScroll) return;

    const currentScrollY = window.scrollY;
    const scrollDirection = currentScrollY < prevScrollY ? 'up' : 'down';

    if (window.scrollY === 0 || prevScrollY == currentScrollY) {
      return;
    }

    if (scrollDirection === 'down') {
      setHiddenOnScroll(true);
    }

    if (scrollDirection === 'up') {
      setHiddenOnScroll(false);
    }

    setPrevScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hideOnScroll, prevScrollY]);

  return (
    <StickyContainer
      ref={ref}
      height={height}
      hideOnScroll={hideOnScroll}
      isHiddenOnScroll={isHiddenOnScroll}
      top={top}
      {...restProps}
    >
      {children}
    </StickyContainer>
  );
};

const StickyContainer = styled(Box)`
  transition: all 0.3s ease-out;
  z-index: 50;
  position: sticky;
  top: ${({ top }) => top || 0};

  ${({ hideOnScroll, isHiddenOnScroll, height }) =>
    hideOnScroll &&
    isHiddenOnScroll &&
    css`
      top: -${height}px;
    `}
`;
