import React from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import truncate from 'lodash/truncate';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ContentfulImage from 'hub/components/ContentfulImage';
import { REDIRECT_LOCALE } from 'shared/constants';

function FeaturedArticleCard({
  title,
  description,
  hubslug,
  heroImage,
  category,
}) {
  const { locale } = useRouter();
  const { t } = useTranslation('static_pages');

  if (locale === REDIRECT_LOCALE) {
    return null;
  }

  return (
    <StyledFeaturedArticleCard>
      <ImageWrapper>
        <Link href={`/hub/${hubslug}`} passHref>
          <a>
            <ContentfulImage
              src={heroImage}
              width={576}
              height={363}
              layout="responsive"
              alt={title}
            />
          </a>
        </Link>
      </ImageWrapper>
      <ContentWrapper>
        {category && (
          <Box>
            <CategoryLabel>
              {t('hub:post.categoryLabel')}: {category.title}
            </CategoryLabel>
          </Box>
        )}
        <Box mb={2}>
          <Link href={`/hub/${hubslug}`} passHref>
            <StyledLink>
              <Typography variant="h4" fontSize="28px" component="h2" pt={2}>
                {title}
              </Typography>
            </StyledLink>
          </Link>
        </Box>

        <Box mb={3}>
          <Typography variant="body1">
            {truncate(description, { length: 250 })}
          </Typography>
        </Box>
        <Link href={`/hub/${hubslug}`} passHref>
          <ButtonLink size="lg" rounded={false} variant="tertiary">
            {t('articleCards.readMore')}
          </ButtonLink>
        </Link>
      </ContentWrapper>
    </StyledFeaturedArticleCard>
  );
}

const CategoryLabel = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: #3c4340;
  line-height: 94%;
  letter-spacing: 0.4px;
`;

const StyledFeaturedArticleCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  width: 50%;
  img {
    border-radius: ${(props) => props.theme.spacing(1)}px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  width: 50%;
  padding: ${(props) => props.theme.spacing(6, 6)};

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: auto;
    padding: ${(props) => props.theme.spacing(2, 0)};
    padding-top: ${({ theme }) => theme.spacing(3)}px;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.palette.text.primary};
`;

const ButtonLink = styled(Button)`
  text-transform: inherit;
  padding: ${(props) => props.theme.spacing(1, 2)};
  font-weight: 500;
  line-height: ${({ theme }) => theme.spacing(3)}px;
  letter-spacing: 0.15px;
  font-size: 14px;
`;

export default FeaturedArticleCard;
