import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@heycater/design-system';
import { Grid } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';

import HubArticleCard from 'hub/components/HubArticleCard';
import { fetchArticleCardsByCategory } from 'lib/contentful/hub';
import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';

function FeaturedPosts({ items, category, title, href }) {
  const { t } = useTranslation('hub');
  const [currentPost, setCurrentPost] = useState(0);
  const mobileBreakpoint = useBreakpointDown('xs');
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    if (category) {
      const fetchCategoryArticles = async () => {
        const data = await fetchArticleCardsByCategory({
          category: category.slug,
          page: 0,
          pageLimit: 3,
        });
        setPosts([...data.items]);
      };
      fetchCategoryArticles().catch(console.error);
    } else {
      setPosts([...items.map((x) => x.fields)]);
    }
  }, []);

  useEffect(() => {
    setCurrentPost(0);
  }, [posts]);

  const contentPart = useMemo(() => posts[currentPost], [currentPost, posts]);

  const back = () => {
    if (currentPost > 0) {
      const contentIndex = currentPost - 1;
      setCurrentPost(contentIndex);
    } else {
      setCurrentPost(posts.length - 1);
    }
  };

  const next = () => {
    if (currentPost < posts.length - 1) {
      const contentIndex = currentPost + 1;
      setCurrentPost(contentIndex);
    } else {
      setCurrentPost(0);
    }
  };

  const dotCount = posts.length;

  const swipeHandlers = useSwipeable({
    onSwiped: ({ dir }) => {
      if (dir === 'Left') {
        next();
      }
      if (dir === 'Right') {
        back();
      }
    },
  });

  if (mobileBreakpoint) {
    return (
      <div {...swipeHandlers}>
        <Box my={4} as="section">
          <StyledContainer>
            <Typography variant="subtitle2">{title}</Typography>
            {href ? (
              <Link href={href} passHref>
                <StyledAnchor>
                  <StyledTypography>{t('featured.seeMore')}</StyledTypography>
                  <ArrowForward />
                </StyledAnchor>
              </Link>
            ) : null}
          </StyledContainer>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: 'column', md: 'row' }}
            mt={{ xs: 2, md: 5 }}
          >
            <HubArticleCard
              title={contentPart?.title}
              description={contentPart?.description}
              hubslug={contentPart?.hubslug}
              heroImage={contentPart?.heroImage}
              tags={contentPart?.tags}
              category={contentPart?.category}
            />
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              mt={3}
              mb={1}
            >
              <Dots>
                {[...new Array(dotCount)].map((_, index) => (
                  <Dot key={index} active={currentPost === index} />
                ))}
              </Dots>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <Box my={8} as="section">
      <StyledContainer>
        <Typography variant="subtitle1">{title}</Typography>
        {href ? (
          <Link href={href} passHref>
            <StyledAnchor>
              <StyledTypography>{t('featured.seeMore')}</StyledTypography>
              <ArrowForward fontSize="small" />
            </StyledAnchor>
          </Link>
        ) : null}
      </StyledContainer>
      <GridContainer
        component="ul"
        container
        direction={'row'}
        spacing={6}
        sx={{
          gridTemplateColumns: 'repeat(5, 1fr)',
        }}
      >
        {posts.map((post) => (
          <GridItem component="li" key={post.slug} xs={12} md={4} item>
            <HubArticleCard
              category={post?.category}
              title={post.title}
              description={post.description}
              hubslug={post.hubslug}
              heroImage={post.heroImage}
              tags={post.tags}
            />
          </GridItem>
        ))}
      </GridContainer>
    </Box>
  );
}

const GridContainer = styled(Grid)`
  padding: 0;
`;

const GridItem = styled(Grid)`
  list-style: none;
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
`;

const Dots = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
  justify-content: center;
`;

const Dot = styled.div`
  background: ${(props) => (props.active ? '#3C4340' : '#E3E4E4')};
  border-radius: 50%;
  flex-shrink: 0;
  width: 11px;
  height: 11px;
`;

const StyledContainer = styled.div`
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-bottom: ${(props) => props.theme.spacing(0)}px;
  }
`;

const StyledTypography = styled(Typography)`
  text-decoration: underline;
  padding-right: ${(props) => props.theme.spacing(1.5)}px;
`;

export default FeaturedPosts;
