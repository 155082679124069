import React from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

function FooterClean({ title, cta, link }) {
  const { t } = useTranslation('static_pages');

  return (
    <StyledBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      pt={{ xs: 2, sm: 5 }}
      pb={{ xs: 5, sm: 7 }}
      px={{ xs: 3, sm: 10, md: 20 }}
    >
      <Typography variant="h3" py={4} textAlign="center">
        {t(title)}
      </Typography>
      <Button
        size="lg"
        variant="primary"
        as="a"
        href={link}
        target="_blank"
        rel="noopener"
        rounded
      >
        {t(cta)}
      </Button>
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  background: ${(props) => props.theme.colors.heroGradient};
`;

export default FooterClean;
