import React from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import truncate from 'lodash/truncate';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ContentfulImage from 'hub/components/ContentfulImage';

function HubArticleCard({
  title,
  description,
  hubslug,
  heroImage,
  category,
  tags,
  ...restProps
}) {
  const { t } = useTranslation('static_pages');

  return (
    <Link href={`/hub/${hubslug}`} passHref>
      <StyledArticleCard {...restProps}>
        <ImageWrapper>
          {heroImage && (
            <ContentfulImage
              src={heroImage}
              width={340}
              height={212}
              alt={title}
            />
          )}
        </ImageWrapper>
        <ContentWrapper>
          <Box pt={2}>
            {tags && tags.length > 0 ? (
              <Box mb={3} height={20}>
                {tags.map((tag) => (
                  <Tag key={tag}>{tag}</Tag>
                ))}
              </Box>
            ) : null}
          </Box>
          {category && (
            <CategoryLabel mt={1} pb={{ md: 1 }}>
              {t('hub:post.categoryLabel')}: {category.title}
            </CategoryLabel>
          )}
          <Box mt={1} mb={2.5}>
            <Link href={`/hub/${hubslug}`} passHref>
              <StyledLink>
                <Typography
                  className="article-title"
                  variant="h5"
                  component="h2"
                  color="textPrimary"
                >
                  {truncate(title, { length: 50 })}
                </Typography>
              </StyledLink>
            </Link>
          </Box>
          <Box mb={3}>
            <Typography variant="body1">
              {truncate(description, { length: 100 })}
            </Typography>
          </Box>
        </ContentWrapper>
        <CatererFooter>
          <Link href={`/hub/${hubslug}`} passHref>
            <ButtonLink rounded={false} variant="tertiary">
              {t('articleCards.readMore')}
            </ButtonLink>
          </Link>
        </CatererFooter>
      </StyledArticleCard>
    </Link>
  );
}

const CategoryLabel = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: #3c4340;
  line-height: 94%;
  letter-spacing: 0.4px;
`;

const StyledArticleCard = styled.a`
  img {
    width: 100%;
    display: block;
    position: relative;
    transition: transform 0.5s ease, filter 0.5s ease;
  }

  &:hover img {
    filter: brightness(0.9);
    transform: scale(1.1);
  }

  &:hover .article-title {
    text-decoration: underline;
  }
  display: flex;
  flex-direction: column;
  text-decoration: inherit;
  height: 100%;
  color: inherit;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  border-radius: ${(props) => props.theme.spacing(1, 1, 1, 1)};
`;

const ContentWrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0, 0)};

  ${(props) => props.theme.breakpoints.down('xs')} {
    min-height: fit-content;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
`;

const ButtonLink = styled(Button)`
  text-transform: inherit;
  padding: ${(props) => props.theme.spacing(1, 2)};
  font-weight: 500;
  line-height: ${({ theme }) => theme.spacing(3)}px;
  letter-spacing: 0.15px;
  font-size: 14px;
`;

const CatererFooter = styled.footer`
  display: flex;
  margin-top: auto;
  align-items: center;
  justify-content: space-between;
`;

const Tag = styled.span`
  display: inline-block;
  padding: ${(props) => props.theme.spacing(0.3, 2)};
  background: ${(props) => props.theme.palette.grey[100]};
  border-radius: 500px;
  font-size: 12px;
  margin-right: ${(props) => props.theme.spacing(1)}px;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
`;

export default HubArticleCard;
